body {
    font-family: "Tahoma", sans-serif;
    margin-top: 10px;
    background-color: #fff1e5;
    font-size: 14px;
    line-height: 20px;
}
.highlight {
    background-color: yellow;
}
.maintable {
    background: #ffffff;
    opacity: 0.9;
    padding: 2em;
    border: black 1px solid;
    border-radius: 33px;
    position: absolute;
    left: 1%;
    right: 1%;
    width: 950px;
    display: block;
    margin: auto;
    z-index: 1;
}

.modal {
    z-index: 100;
    position: fixed;
    left: 0;   
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(160, 194, 191, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content > h4 {
    margin-top: 40px;
    margin-bottom: 10px;
}





.modal-content {
    background: #ffffff;
    opacity: 1;
    padding: 2em;
    border: black 1px solid;
    border-radius: 10px;
    position: absolute;
    left: 1%;
    right: 1%;
    width: 75%;
    display: block;
    margin: auto;
    position: absolute;
    width: 50%;
    text-align: center;
    margin-top: 30px;
}

.form-control {
    display: block;
    width: 96.8%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.form-group {
    margin-bottom: 15px;
}

img {
    margin-bottom: 10px;
}

h3 {
    font-family:  "Comic Sans MS";
    text-align: center;
    font-weight: normal;
    color: #3c0909;
    font-size: 28px;

}

p {
    margin: 0 0 10px;

}

button {
    font-size: 16px;
    padding: 8px 5px;
    white-space: nowrap;
}

h4 {
    text-align: center;
    font-weight: normal;
    color: #3c0909;
    font-size: 22px;
}

a {
    color: #337ab7;
    text-decoration: none;
}

.contentContainer {
    padding: 1em;
}

.activeDirt {
    margin-bottom: 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 20%;

    position: initial;
    background: #f3f3f3;
    opacity: 1;
    /* padding: 1em; */
    border: black 1px solid;
    border-radius: 10px;
    float: left;
    
    z-index: 2;
}

.ownerDirt {
    margin-bottom: 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 20%;

    position: initial;
    background: #e0f8d2;
    opacity: 1;
    /* padding: 1em; */
    border: black 1px solid;
    border-radius: 10px;
    float: left;
   
    z-index: 2;
}

.inactiveDirt {
    margin-bottom: 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 20%;
    position: initial;
    background: #f3f3f3;
    opacity: 1;
    /* padding: 1em; */
    border: black 1px solid;
    border-radius: 10px;
    float: left;
   
    z-index: 2;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

.virginDirtContainer {
    margin-top: 16px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 20%;
    position: initial;
    background: #f3f3f3;
    opacity: 1;
   
    border: black 1px solid;
    border-radius: 10px;
    z-index: 2;
}

h5 {
    font-family: "Comic Sans MS", "Calibri", "Tahoma";
    text-align: center;
    font-weight: bold;
    color: #3c0909;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 7px;

}

h5.release-text {
    font-family: "Tahoma";
    text-align: center;
    font-weight: bold;
    color: #3c0909;
    font-size: 14px;
}

.buttonPrimary {
    border-radius: 4px;
    display: block;
    width: 100%;
    color: #fff;
    background-color: #337ab7;
    border: none;
    white-space: nowrap;
    cursor: pointer;
}

.buttonPrimary:hover {
    background-color: #1c4c77;
}

.buttonDisabled {
    border-radius: 4px;
    opacity: 0.65;
    display: block;
    width: 100%;
    color: #fff;
    background-color: #337ab7;
    border: none;
    white-space: nowrap;
    cursor: not-allowed;

}

.buttonSoon {
    border-radius: 4px;
    opacity: 0.65;
    display: block;
    width: 100%;
    color: #fff;
    background-color: #cc9659;
    border: none;
    white-space: nowrap;
    cursor: not-allowed;

}

.returnToWebsite {
    margin-top: 40px;
    cursor: pointer;
}

.dirtManagementButton {
    background: none;
    border: none;
    padding: 0;
    color: #337ab7;
    font-size: 14px;
    cursor: pointer;
    font-family: 'Tahoma';
    display: inline-block;
}

.ownerLabel {
    position: absolute;
    width: 160px;
    margin-top: 0;
    color: rgb(17, 102, 43);
    white-space: nowrap;
}

.priceLabel {
    position: absolute;
    width: 160px;
    margin-top: 0;
    color: rgb(17, 102, 43);
    margin-top: -10px;
    line-height: 16px;
}

.buttonFull {
    width: 100%;
    color: #fff;
    background-color: #337ab7;
    border: none;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 4px;
    padding: 12px 0;
}

.buttonFull:disabled {
    background-color: #95b4cf;
    cursor: not-allowed;
}